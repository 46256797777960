import { Box, Slider, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

export const EDGES = {
  min: 0,
  max: 100
};

const greenColor = green[700];
const greyColor = grey[600];
const sliderTransform = 'translate(0, 2px)';
const textAlign = 'left';
const bottomPadding = '3px';
const smallFontSize = '0.8rem';
const styles = {
  typography: {
    color: greyColor,
    textAlign: textAlign,
    paddingBottom: bottomPadding,
    fontSize: smallFontSize
  },
  typographyFocus: {
    color: greenColor,
    textAlign: textAlign,
    paddingBottom: bottomPadding,
    fontSize: smallFontSize
  },
  slider: {
    transform: sliderTransform,
    color: greyColor
  },
  sliderFocus: {
    transform: sliderTransform,
    color: greenColor
  }
};

export default function RangeSelect({
  valueTo,
  valueFrom,
  setValueFrom,
  setValueTo
}) {
  const [sliderFocus, setSliderFocus] = useState(false);
  const [containerFocus, setContainerFocus] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (valueFrom < EDGES.min || !valueFrom) {
      setValueFrom(EDGES.min);
    } else if (valueFrom > EDGES.max) {
      setValueFrom(EDGES.max);
    }
    if (valueTo < EDGES.min) {
      setValueTo(EDGES.min);
    } else if (valueTo > EDGES.max || !valueTo) {
      setValueTo(EDGES.max);
    }
  }, [valueFrom, valueTo, setValueFrom, setValueTo]);
  return (
    <Box
      onFocus={() => {
        setContainerFocus(true);
      }}
      onBlur={() => {
        setContainerFocus(false);
      }}
    >
      <Typography
        style={containerFocus ? styles.typographyFocus : styles.typography}
      >
        {t('Score range (%)')}
      </Typography>
      <Grid container direction="row" spacing={2}>
        <Grid xs={2} style={styles.inputs} item>
          <Input
            value={valueFrom}
            type="number"
            onChange={(event) => {
              setValueFrom(event.target.value);
            }}
          />
        </Grid>
        <Grid xs={7} item>
          <Slider
            value={[valueFrom, valueTo]}
            onChange={(event, value) => {
              setValueFrom(value[0]);
              setValueTo(value[1]);
            }}
            min={EDGES.min}
            max={EDGES.max}
            color="primary"
            style={sliderFocus ? styles.sliderFocus : styles.slider}
            valueLabelDisplay="auto"
            onFocus={() => {
              setSliderFocus(true);
            }}
            onBlur={() => {
              setSliderFocus(false);
            }}
          />
        </Grid>
        <Grid style={styles.inputs} xs={3} item>
          <Input
            value={valueTo}
            type="number"
            onChange={(event) => {
              setValueTo(event.target.value);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
