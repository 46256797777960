import React, {useEffect, useRef, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import {Add, Remove} from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import {useTranslation} from 'react-i18next';
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const RAGText = ({title, source, page_content}) => {
    return (
        <div>
            <p><b>{title}</b></p>
            <p>{source}</p>
            <p>{page_content}</p>
        </div>
    )
}
const adjustText = (textToAdjust) => {
    const index = textToAdjust?.indexOf("[{");
    let text
    if (index > 0) {
        text = textToAdjust.substring(0, index)
        let rag = []
        let nextIndex = textToAdjust?.indexOf("}]")
        if (nextIndex <= 0) return textToAdjust
        try {
            let json = JSON.parse(textToAdjust.substring(index, nextIndex + 2))
            for (let item in json) {
                rag.push(<RAGText key={item} {...json[item]}/>)
            }
        } catch (e) {
            rag.push(textToAdjust.substring(index, nextIndex + 2));
        }

        return <>{text}<br/><br/>{rag}</>;
    }
    return textToAdjust;
};

const processText = (textToAdjust) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const urlsInText = textToAdjust?.match(urlPattern);
    const urlsArr = []
    if (urlsInText) {
        urlsInText.forEach(url => {
            const index = url.indexOf('","title');
            let validUrl = url
            if (index > 0) {
                validUrl = url.substring(0, index)
            }
            if (url.endsWith('"')) {
                validUrl = url.substring(0, url.length - 1)
            }
            urlsArr.push(<a href={validUrl} target="_blank" rel="noreferrer">{validUrl}</a>)
            urlsArr.push(<br/>)

        });
    }
    return urlsArr
}

const useStyles = makeStyles((theme) => ({
    paperRight: {
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingBottom: '4px',
    },
    expanded: {
        WebkitLineClamp: 'unset',
        overflow: 'visible',
    },
    expandButton: {
        height: '30px',
        display: 'flex',
        alignSelf: 'end',
    }
}));

const ConversationItemResponseDetails = (props) => {
    const classes = props.styles;
    const {textFull} = props;
    const [openOptions, setOpenOptions] = useState(false);
    const {t} = useTranslation();
    const [text,] = useState(props.message.text);
    const myClasses = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef(null);
    let elem = <>{adjustText(text)}{
        <br/>}
        <div>{processText(text)}</div>
    </>

    useEffect(() => {
        if (textRef.current) {
            const computedStyle = getComputedStyle(textRef.current);
            const lineHeight = parseFloat(computedStyle.lineHeight) || 20;
            const maxHeight = lineHeight * 5;
            setIsOverflowing(textRef.current.scrollHeight > maxHeight);
        }
    }, [textFull]);

    return (
        <React.Fragment>
            {props.message.type && props.message.type === 'TEXT' ? (
                <Grid item container>
                    <Grid item xs={3}>
            <span style={{fontSize: '12px', color: '#999999'}}>
              {t('Input type')}: {props.inputType}
            </span>
                    </Grid>
                    <Grid item xs={9} style={{display: 'flex'}}>
                        <Paper
                            ref={textRef}
                            className={`${myClasses.paperRight} ${classes.paperRight} ${expanded ? myClasses.expanded : ''}`}
                        >
                            {elem}
                        </Paper>
                        {isOverflowing && (
                            <IconButton
                                className={myClasses.expandButton}
                                size="small"
                                onClick={() => setExpanded(!expanded)}
                            >
                                <ExpandMoreIcon/>
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ) : null}
            {props.message.type && props.message.type === 'OPTIONS' ? (
                openOptions ? (
                    <Grid item container justify="flex-end">
                        <Button
                            size="small"
                            startIcon={<Remove/>}
                            onClick={() => setOpenOptions(false)}
                        >
                            Options{' '}
                            {props.message.options
                                ? '(' + props.message.options.length + ')'
                                : null}
                        </Button>
                    </Grid>
                ) : (
                    <Grid item container justify="flex-end">
                        <Button
                            size="small"
                            startIcon={<Add/>}
                            onClick={() => setOpenOptions(true)}
                        >
                            Options{' '}
                            {props.message.options
                                ? '(' + props.message.options.length + ')'
                                : null}
                        </Button>
                    </Grid>
                )
            ) : null}
            {props.message.type &&
            props.message.options &&
            props.message.type === 'OPTIONS' ? (
                <Grid item container justify="flex-end">
                    <Collapse in={openOptions}>
                        {props.message.options.map((option, index) => {
                            return (
                                <Paper key={index} className={classes.paperRightOptions}>
                                    {option.text}
                                </Paper>
                            );
                        })}
                    </Collapse>
                </Grid>
            ) : null}
        </React.Fragment>
    );
};

export default ConversationItemResponseDetails;
