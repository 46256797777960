import React, {useEffect, useState} from 'react';
// import AppsDropdown from './AppsDropdown/AppsDropdown';
import UserMenu from './UserMenu/UserMenu';
import Alert from '@material-ui/lab/Alert';

const Header = ({user, selectedLanguage, handleLangSwitch, switchLoggedInState, loginError}) => {
    // const [appsAnchorEl, setAppsAnchorEl] = useState(null);
    // const [appsDropdownOpen, setAppsDropdownOpen] = useState(false);

    // const recordAppsButtonPosition = (event) => {
    //     setAppsAnchorEl(event.currentTarget);
    //     setAppsDropdownOpen(true);
    // };

    // const closeAppsDropdown = () => {
    //     setAppsDropdownOpen(false);
    // };

    const [isProd, setIsProd] = useState(false);
    useEffect(() => {
        if (window.location.origin.includes('staging') || window.location.origin.includes('test')
            || window.location.origin.includes('localhost')) {
            setIsProd(false);
        } else {
            setIsProd(true);
        }
    }, []);

    return (
        <div className="header">
            <img alt="Main Logo" src="/logo.png" width={100} className="p-1.5"/>
            <h1 className="text-xl text-center text-white font-normal mb-0">Training Tool
                {isProd &&
                    <span className="pl-2 text-yellow-500"> - PROD</span>
                }</h1>
            <div className="flex gap-4 items-center ml-auto">
                {/* <div
                    onClick={recordAppsButtonPosition}
                    className="bg-[#ebebebb2] hover:bg-[#dfdfdfa1] fill-white transition-all cursor-pointer flex w-9 h-9 rounded-full"
                >
                    <img alt="apps" className="flex m-auto" src="/apps.svg" />
                </div>
                <AppsDropdown
                    appsAnchorEl={appsAnchorEl}
                    appsDropdownOpen={appsDropdownOpen}
                    closeAppsDropdown={closeAppsDropdown}
                /> */}
                <UserMenu
                    user={user}
                    selectedLanguage={selectedLanguage}
                    handleLangSwitch={handleLangSwitch}
                    switchLoggedInState={switchLoggedInState}
                />
            </div>
            {loginError.length > 0 && (
                <Alert className="absolute top-14 right-4" severity="error">{loginError}</Alert>
            )}
        </div>
    );
};

export default Header;